import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Marker, Tooltip } from "react-leaflet";
import { DamParameter } from "src/types/Types";

type DamParameterMarkerProps = {
  damParameter: DamParameter;
  tooltipOn?: boolean;
};

const DamParameterMarker = (props: DamParameterMarkerProps) => {
  const { damParameter, tooltipOn = true } = props;
  const defaultColor = "#1565C0";

  const divIcon = L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(
      <div style={{ color: damParameter.color ? damParameter.color : defaultColor }}>
        <div style={{ fontFamily: "Inter, sans-serif", fontSize: 10, width: "500px" }}>{damParameter.remarks}</div>
      </div>
    )
  });

  return (
    <Marker icon={divIcon} position={[damParameter.wgs84Latitude, damParameter.wgs84Longitude]}>
      {tooltipOn && <Tooltip direction="right">{`[${damParameter.dmsLatitude} ${damParameter.dmsLongitude}]`}</Tooltip>}
    </Marker>
  );
};

export default DamParameterMarker;
