import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getAirportsFromIds } from "src/apis/airports/airportsApi";
import { getAirwaysFromIds } from "src/apis/airways/airwaysApis";
import { getAreasFromIds } from "src/apis/areas/areasApi";
import { getComputedAreasFromIds } from "src/apis/computedareas/computedAreasApis";
import { getDamParametersFromIds } from "src/apis/damparameters/damParametersApi";
import { getHoldingsFromIds } from "src/apis/holdings/holdingsApi";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getLinesFromIds } from "src/apis/lines/linesApi";
import { getMapTextsFromIds, updateMapText } from "src/apis/maptexts/mapTextsApi";
import { getPointsFromIds } from "src/apis/points/pointsApi";
import { getHighwayRampsFromIds } from "src/apis/swisstopo/highwayRampsApi";
import { getHighwaysFromIds } from "src/apis/swisstopo/highwaysApi";
import { getHighwayTunnelsFromIds } from "src/apis/swisstopo/highwayTunnelsApi";
import { getLakesFromIds } from "src/apis/swisstopo/lakesApi";
import { getMajorRoadsFromIds } from "src/apis/swisstopo/majorRoadsApi";
import { getRailwaysFromIds } from "src/apis/swisstopo/railwaysApi";
import { getUrbanAreasFromIds } from "src/apis/swisstopo/urbanAreasApi";
import { getSymbolWithColor } from "src/apis/symbols/symbolsApi";
import { getWaypointsFromIds } from "src/apis/waypoints/waypointsApi";
import RadarMapViewer from "src/components/radarmapviewer/RadarMapViewer";

import {
  Airport,
  Area,
  ComputedArea,
  GraphicAttribute,
  Highway,
  HighwayRamp,
  HighwayTunnel,
  Holding,
  Lake,
  Line,
  MajorRoad,
  MapElementReference,
  MapText,
  DamParameter,
  Point,
  Railway,
  UrbanArea,
  Waypoint,
  Airway
} from "src/types/Types";

type MapEditorPreviewProps = {
  mapElementReferences: MapElementReference[];
  graphicAttributes: GraphicAttribute[];
};

type LineOptions = {
  color?: string;
  dashArray?: string | number[];
  weight?: number;
  priority?: number;
};

type AreaOptions = {
  color?: string;
  fillColor?: string;
  priority?: number;
};

type SymbolWithColor = { symbolName: string; color: string };

const parseLinePattern = (linePattern: string) => {
  if (linePattern === null || linePattern === undefined) {
    return "";
  }
  if (linePattern.startsWith("SOLID")) {
    return "";
  }
  if (linePattern.startsWith("USERSTYLE")) {
    const match = linePattern.match(/(USERSTYLE) (\d{2})(\d{2})/);
    if (match !== null) {
      return `${match[2]},${match[3]}`;
    }
  }
  return "";
};

const MapEditorPreview = (props: MapEditorPreviewProps) => {
  const versionTag = useVersionTag();
  const { graphicAttributes, mapElementReferences } = props;

  const [lines, setLines] = useState<Line[]>([]);
  const [areas, setAreas] = useState<Area[]>([]);
  const [computedAreas, setComputedAreas] = useState<ComputedArea[]>([]);
  const [mapTexts, setMapTexts] = useState<MapText[]>([]);
  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);
  const [points, setPoints] = useState<Point[]>([]);
  const [airports, setAirports] = useState<Airport[]>([]);
  const [holdings, setHoldings] = useState<Holding[]>([]);
  const [lakes, setLakes] = useState<Lake[]>([]);
  const [urbanAreas, setUrbanAreas] = useState<UrbanArea[]>([]);
  const [highways, setHighways] = useState<Highway[]>([]);
  const [highwayRamps, setHighwayRamps] = useState<HighwayRamp[]>([]);
  const [highwayTunnels, setHighwayTunnels] = useState<HighwayTunnel[]>([]);
  const [majorRoads, setMajorRoads] = useState<MajorRoad[]>([]);
  const [railways, setRailways] = useState<Railway[]>([]);
  const [damParameters, setDamParameters] = useState<DamParameter[]>([]);
  const [airways, setAirways] = useState<Airway[]>([]);

  const [selectedText, setSelectedText] = useState<MapText>();
  const selectedTextRef = useRef(selectedText);

  const updateLinesFromRefs = (refs: MapElementReference[]) => {
    const lineRefs = refs.filter((ref) => ref.referenceType === "LINE");
    const lineColorsMap = new Map<number, LineOptions>();

    for (let i = 0; i < lineRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === lineRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        lineColorsMap.set(lineRefs[i].elementId, { color: graphicAttribute[0].color, dashArray: parseLinePattern(graphicAttribute[0].linePattern) });
      }
    }

    const lineIds = lineRefs.map((l) => l.elementId).toString();
    if (lineIds.length > 0) {
      getLinesFromIds(versionTag, lineIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            result[i].color = lineColorsMap.get(+result[i].id)?.color;
            result[i].dashArray = lineColorsMap.get(+result[i].id)?.dashArray;
          }
          setLines([...result]);
        })
        .catch(() => {
          toast.error("Failed to add lines!");
        });
    } else {
      setLines([]);
    }
  };

  const updateAreasFromRefs = (refs: MapElementReference[]) => {
    const areaRefs = refs.filter((ref) => ref.referenceType === "AREA");
    const areaIds = areaRefs.map((ref) => ref.elementId).toString();
    const areaColorsMap = new Map<number, AreaOptions>();

    for (let i = 0; i < areaRefs.length; i++) {
      const lineAttribute = graphicAttributes.filter((ga) => ga.name === areaRefs[i].auxiliaryAttributes && ga.type === "LINE");
      if (lineAttribute.length > 0) {
        areaColorsMap.set(areaRefs[i].elementId, { color: lineAttribute[0].color });
      }
      const fillAttribute = graphicAttributes.filter((ga) => ga.name === areaRefs[i].attributes && ga.type === "AREA");
      if (fillAttribute.length > 0) {
        const areaOptions = areaColorsMap.get(areaRefs[i].elementId);
        if (areaOptions) {
          areaColorsMap.set(areaRefs[i].elementId, { ...areaOptions, fillColor: fillAttribute[0].color, priority: fillAttribute[0].priority });
        } else {
          areaColorsMap.set(areaRefs[i].elementId, { fillColor: fillAttribute[0].color, priority: fillAttribute[0].priority });
        }
      }
    }
    if (areaIds.length > 0) {
      getAreasFromIds(versionTag, areaIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            result[i].color = areaColorsMap.get(+result[i].id)?.color;
            result[i].fillColor = areaColorsMap.get(+result[i].id)?.fillColor;
            result[i].priority = areaColorsMap.get(+result[i].id)?.priority ? areaColorsMap.get(+result[i].id)?.priority : 0;
          }
          setAreas([...result]);
        })
        .catch(() => {
          toast.error("Failed to add lines!");
        });
    } else {
      setAreas([]);
    }
  };

  const updateComputedAreasFromRefs = (refs: MapElementReference[]) => {
    const computedAreasRefs = refs.filter((ref) => ref.referenceType === "COMPUTED_AREA");
    const computedAreasIds = computedAreasRefs.map((ca) => ca.elementId);
    const computedAreasColorsMap = new Map<number, AreaOptions>();

    for (let i = 0; i < computedAreasRefs.length; i++) {
      const lineAttribute = graphicAttributes.filter((ga) => ga.name === computedAreasRefs[i].auxiliaryAttributes && ga.type === "LINE");
      if (lineAttribute.length > 0) {
        computedAreasColorsMap.set(computedAreasRefs[i].elementId, { color: lineAttribute[0].color });
      }
      const fillAttribute = graphicAttributes.filter((ga) => ga.name === computedAreasRefs[i].attributes && ga.type === "AREA");
      if (fillAttribute.length > 0) {
        const areaOptions = computedAreasColorsMap.get(computedAreasRefs[i].elementId);
        if (areaOptions) {
          computedAreasColorsMap.set(computedAreasRefs[i].elementId, { ...areaOptions, fillColor: fillAttribute[0].color });
        } else {
          computedAreasColorsMap.set(computedAreasRefs[i].elementId, { fillColor: fillAttribute[0].color });
        }
      }
    }
    if (computedAreasIds.length > 0) {
      getComputedAreasFromIds(versionTag, computedAreasIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            result[i].color = computedAreasColorsMap.get(+result[i].id)?.color;
            result[i].fillColor = computedAreasColorsMap.get(+result[i].id)?.fillColor;
          }
          setComputedAreas([...result]);
        })
        .catch(() => {
          toast.error("Failed to add computed areas!");
        });
    } else {
      setComputedAreas([]);
    }
  };

  const updateMapTextsFromRefs = (refs: MapElementReference[]) => {
    const textRefs = refs.filter((ref) => ref.referenceType === "TEXT");
    const textColorsMap = new Map<number, string>();

    for (let i = 0; i < textRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === textRefs[i].attributes && ga.type === "TEXT");
      if (graphicAttribute.length > 0) {
        textColorsMap.set(textRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    const textIds = textRefs.map((t) => t.elementId).toString();
    if (textIds.length > 0) {
      getMapTextsFromIds(versionTag, textIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            result[i].color = textColorsMap.get(+result[i].id);
          }
          setMapTexts([...result]);
        })
        .catch(() => {
          toast.error("Failed to retrieve texts");
        });
    } else {
      setMapTexts([]);
    }
  };

  const updateDamParametersFromRefs = (refs: MapElementReference[]) => {
    const damParameterRefs = refs.filter((ref) => ref.referenceType === "DAM_PARAMETER");
    const damParameterColorMap = new Map<number, string>();

    console.log("dam parameters: " + JSON.stringify(damParameterRefs));

    for (let i = 0; i < damParameterRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === damParameterRefs[i].attributes && ga.type === "TEXT");
      if (graphicAttribute.length > 0) {
        damParameterColorMap.set(damParameterRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    const damParameterIds = damParameterRefs.map((t) => t.elementId);
    if (damParameterIds.length > 0) {
      getDamParametersFromIds(versionTag, damParameterIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            result[i].color = damParameterColorMap.get(+result[i].id);
          }
          setDamParameters([...result]);
        })
        .catch(() => {
          toast.error("Failed to retrieve Dam parameters");
        });
    } else {
      setDamParameters([]);
    }
  };

  const updateWaypointsFromRefs = async (refs: MapElementReference[]) => {
    const waypointsRefs = refs.filter((ref) => ref.referenceType === "WAYPOINT");
    const waypointAttributesMap = new Map<number, SymbolWithColor>();
    const symbolImages = new Map<string, string | null>(); /* image is base64 encoded */

    for (let i = 0; i < waypointsRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === waypointsRefs[i].attributes && ga.type === "POINT");
      if (graphicAttribute.length > 0) {
        const symbolWithColor = { symbolName: graphicAttribute[0].symbol, color: graphicAttribute[0].color };
        waypointAttributesMap.set(waypointsRefs[i].elementId, symbolWithColor);

        if (!symbolImages.has(JSON.stringify(symbolWithColor))) {
          // fetch symbol image with correct color from api
          const symbolWithImage = await getSymbolWithColor(versionTag, graphicAttribute[0].symbol, graphicAttribute[0].color);
          symbolImages.set(JSON.stringify(symbolWithColor), symbolWithImage.symbolImage);
        }
      }
    }
    const waypointIds = waypointsRefs.map((wp) => wp.elementId).toString();
    if (waypointIds.length > 0) {
      getWaypointsFromIds(versionTag, waypointIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            const symbolWithColor = waypointAttributesMap.get(+result[i].id);
            if (symbolWithColor) {
              const symbolImage = symbolImages.get(JSON.stringify(symbolWithColor));
              if (symbolImage) {
                result[i].symbolImage = symbolImage;
              }
            }
          }
          setWaypoints(result);
        })
        .catch(() => {
          toast.error("failed to get waypoint ids!");
        });
    } else {
      setWaypoints([]);
    }
  };

  const updateHoldingsFromRefs = async (refs: MapElementReference[]) => {
    const holdingsRefs = refs.filter((ref) => ref.referenceType === "HOLDING");
    const holdinghAttributesMap = new Map<number, SymbolWithColor>();
    const symbolImages = new Map<string, string | null>(); /* image is base64 encoded */

    for (let i = 0; i < holdingsRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === holdingsRefs[i].attributes && ga.type === "POINT");
      if (graphicAttribute.length > 0) {
        const symbolWithColor = { symbolName: graphicAttribute[0].symbol, color: graphicAttribute[0].color };
        holdinghAttributesMap.set(holdingsRefs[i].elementId, symbolWithColor);

        if (!symbolImages.has(JSON.stringify(symbolWithColor))) {
          // fetch symbol image with correct color from api
          const symbolWithImage = await getSymbolWithColor(versionTag, graphicAttribute[0].symbol, graphicAttribute[0].color);
          symbolImages.set(JSON.stringify(symbolWithColor), symbolWithImage.symbolImage);
        }
      }
    }
    const holdingIds = holdingsRefs.map((h) => h.elementId);
    if (holdingIds.length > 0) {
      getHoldingsFromIds(versionTag, holdingIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            const symbolWithColor = holdinghAttributesMap.get(+result[i].id);
            if (symbolWithColor) {
              const symbolImage = symbolImages.get(JSON.stringify(symbolWithColor));
              if (symbolImage) {
                result[i].symbolImage = symbolImage;
              }
            }
          }
          setHoldings(result);
        })
        .catch(() => {
          toast.error("failed to get holding ids!");
        });
    } else {
      setHoldings([]);
    }
  };

  const updatePointsFromRefs = async (refs: MapElementReference[]) => {
    const pointRefs = mapElementReferences.filter((ref) => ref.referenceType === "POINT");
    const pointAttributesMap = new Map<number, SymbolWithColor>();
    const symbolImages = new Map<string, string | null>(); /* image is base64 encoded */

    for (let i = 0; i < pointRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === pointRefs[i].attributes && ga.type === "POINT");
      if (graphicAttribute.length > 0) {
        const symbolWithColor = { symbolName: graphicAttribute[0].symbol, color: graphicAttribute[0].color };
        pointAttributesMap.set(pointRefs[i].elementId, symbolWithColor);

        if (!symbolImages.has(JSON.stringify(symbolWithColor))) {
          // fetch symbol image with correct color from api
          const symbolWithImage = await getSymbolWithColor(versionTag, graphicAttribute[0].symbol, graphicAttribute[0].color);
          symbolImages.set(JSON.stringify(symbolWithColor), symbolWithImage.symbolImage);
        }
      }
    }

    const pointIds = pointRefs.map((p) => p.elementId).toString();
    if (pointIds.length > 0) {
      getPointsFromIds(versionTag, pointIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            const symbolWithColor = pointAttributesMap.get(+result[i].id);
            if (symbolWithColor) {
              const symbolImage = symbolImages.get(JSON.stringify(symbolWithColor));
              if (symbolImage) {
                result[i].symbolImage = symbolImage;
              }
            }
          }
          setPoints(result);
        })
        .catch(() => {
          toast.error("failed to get point ids!");
        });
    } else {
      setPoints([]);
    }
  };

  const updateAirportsFromRefs = async (refs: MapElementReference[]) => {
    const airportRefs = refs.filter((ref) => ref.referenceType === "AIRPORT");
    const airportAttributesMap = new Map<number, SymbolWithColor>();
    const symbolImages = new Map<string, string | null>(); /* image is base64 encoded */

    for (let i = 0; i < airportRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === airportRefs[i].attributes && ga.type === "AIRPORT");
      if (graphicAttribute.length > 0) {
        const symbolWithColor = { symbolName: graphicAttribute[0].symbol, color: graphicAttribute[0].color };
        airportAttributesMap.set(airportRefs[i].elementId, symbolWithColor);
        if (!symbolImages.has(JSON.stringify(symbolWithColor))) {
          // fetch symbol image with correct color from api
          const symbolWithImage = await getSymbolWithColor(versionTag, graphicAttribute[0].symbol, graphicAttribute[0].color);
          symbolImages.set(JSON.stringify(symbolWithColor), symbolWithImage.symbolImage);
        }
      }
    }

    const airportIds = airportRefs.map((a) => a.elementId).toString();
    if (airportIds.length > 0) {
      getAirportsFromIds(versionTag, airportIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            const symbolWithColor = airportAttributesMap.get(+result[i].id);
            if (symbolWithColor) {
              const symbolImage = symbolImages.get(JSON.stringify(symbolWithColor));
              if (symbolImage) {
                result[i].symbolImage = symbolImage;
                result[i].color = symbolWithColor.color;
              }
            }
          }
          setAirports(result);
        })
        .catch(() => {
          toast.error("failed to get point ids!");
        });
    } else {
      setAirports([]);
    }
  };

  const updateLakesFromRefs = (refs: MapElementReference[]) => {
    const lakeRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_LAKES");
    const lakeIds = lakeRefs.map((l) => l.elementId).toString();
    const lakeColorsMap = new Map<number, string>();

    for (let i = 0; i < lakeRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === lakeRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        lakeColorsMap.set(lakeRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (lakeIds.length > 0) {
      getLakesFromIds(versionTag, lakeIds).then((result) => {
        const lakesResult = result as Lake[];
        for (let i = 0; i < lakesResult.length; i++) {
          lakesResult[i].color = lakeColorsMap.get(+result[i].id);
        }
        setLakes(lakesResult);
      });
    } else {
      setLakes([]);
    }
  };

  const updateUrbanAreasFromRefs = (refs: MapElementReference[]) => {
    const urbanAreaRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_URBAN_AREAS");
    const urbanAreaIds = urbanAreaRefs.map((ua) => ua.elementId);
    const urbanAreaColorsMap = new Map<number, string>();

    for (let i = 0; i < urbanAreaRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === urbanAreaRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        urbanAreaColorsMap.set(urbanAreaRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (urbanAreaIds.length > 0) {
      getUrbanAreasFromIds(versionTag, urbanAreaIds).then((result) => {
        const urbanAreasResult = result as UrbanArea[];
        for (let i = 0; i < urbanAreasResult.length; i++) {
          urbanAreasResult[i].color = urbanAreaColorsMap.get(+result[i].id);
        }
        setUrbanAreas(urbanAreasResult);
      });
    } else {
      setUrbanAreas([]);
    }
  };

  const updateHighwaysFromRefs = (refs: MapElementReference[]) => {
    const highwayRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_HIGHWAYS");

    const highwayIds = highwayRefs.map((ua) => ua.elementId);
    const colorMap = new Map<number, string>();

    for (let i = 0; i < highwayRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === highwayRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        colorMap.set(highwayRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (highwayRefs.length > 0) {
      getHighwaysFromIds(versionTag, highwayIds)
        .then((result) => {
          const highwaysResult = result as Highway[];
          for (let i = 0; i < highwaysResult.length; i++) {
            highwaysResult[i].color = colorMap.get(+highwaysResult[i].id);
          }
          setHighways(highwaysResult);
        })
        .catch(() => {
          toast.error("Failed to load highways");
        });
    } else {
      setHighways([]);
    }
  };

  const updateHighwayRampsFromRefs = (refs: MapElementReference[]) => {
    const highwayRampsRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_HIGHWAY_RAMPS");

    const highwayRampsIds = highwayRampsRefs.map((ua) => ua.elementId);
    const colorMap = new Map<number, string>();

    for (let i = 0; i < highwayRampsRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === highwayRampsRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        colorMap.set(highwayRampsRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (highwayRampsRefs.length > 0) {
      getHighwayRampsFromIds(versionTag, highwayRampsIds)
        .then((result) => {
          const highwayRampsResult = result as HighwayRamp[];
          for (let i = 0; i < highwayRampsResult.length; i++) {
            highwayRampsResult[i].color = colorMap.get(+highwayRampsResult[i].id);
          }
          setHighwayRamps(highwayRampsResult);
        })
        .catch(() => {
          toast.error("Failed to load highway ramps");
        });
    } else {
      setHighwayRamps([]);
    }
  };

  const updateHighwayTunnelsFromRefs = (refs: MapElementReference[]) => {
    const highwayTunnelsRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_HIGHWAY_TUNNELS");

    const highwayTunnelsIds = highwayTunnelsRefs.map((ht) => ht.elementId);
    const colorMap = new Map<number, string>();

    for (let i = 0; i < highwayTunnelsRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === highwayTunnelsRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        colorMap.set(highwayTunnelsRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (highwayTunnelsRefs.length > 0) {
      getHighwayTunnelsFromIds(versionTag, highwayTunnelsIds)
        .then((result) => {
          const highwayTunnelsResult = result as HighwayTunnel[];
          for (let i = 0; i < highwayTunnelsResult.length; i++) {
            highwayTunnelsResult[i].color = colorMap.get(+highwayTunnelsResult[i].id);
          }
          setHighwayTunnels(highwayTunnelsResult);
        })
        .catch(() => {
          toast.error("Failed to load highway tunnels");
        });
    } else {
      setHighwayTunnels([]);
    }
  };

  const updateMajorRoadsFromRefs = (refs: MapElementReference[]) => {
    const majorRoadsRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_MAJOR_ROADS");

    const majorRoadsIds = majorRoadsRefs.map((r) => r.elementId);
    const colorMap = new Map<number, string>();

    for (let i = 0; i < majorRoadsRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === majorRoadsRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        colorMap.set(majorRoadsRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (majorRoadsRefs.length > 0) {
      getMajorRoadsFromIds(versionTag, majorRoadsIds)
        .then((result) => {
          const majorRoadsResult = result as MajorRoad[];
          for (let i = 0; i < majorRoadsResult.length; i++) {
            majorRoadsResult[i].color = colorMap.get(+majorRoadsResult[i].id);
          }
          setMajorRoads(majorRoadsResult);
        })
        .catch(() => {
          toast.error("Failed to load major roads");
        });
    } else {
      setMajorRoads([]);
    }
  };

  const updateRailwaysFromRefs = (refs: MapElementReference[]) => {
    const railwayRefs = refs.filter((ref) => ref.referenceType === "SWISSTOPO_RAILWAYS");

    const railwaysIds = railwayRefs.map((rw) => rw.elementId);
    const colorMap = new Map<number, string>();

    for (let i = 0; i < railwayRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === railwayRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        colorMap.set(railwayRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (railwayRefs.length > 0) {
      getRailwaysFromIds(versionTag, railwaysIds)
        .then((result) => {
          const railwaysResult = result as Railway[];
          for (let i = 0; i < railwaysResult.length; i++) {
            railwaysResult[i].color = colorMap.get(+railwaysResult[i].id);
          }
          setRailways(railwaysResult);
        })
        .catch(() => {
          toast.error("Failed to load railways");
        });
    } else {
      setRailways([]);
    }
  };

  const updateAirwaysFromRefs = (refs: MapElementReference[]) => {
    const airwaysRefs = refs.filter((ref) => ref.referenceType === "AIRWAY");
    const airwayIds = airwaysRefs.map((a) => a.elementId);
    const colorMap = new Map<number, string>();

    for (let i = 0; i < airwaysRefs.length; i++) {
      const graphicAttribute = graphicAttributes.filter((ga) => ga.name === airwaysRefs[i].attributes && ga.type === "LINE");
      if (graphicAttribute.length > 0) {
        colorMap.set(airwaysRefs[i].elementId, graphicAttribute[0].color);
      }
    }

    if (airwayIds.length > 0) {
      getAirwaysFromIds(versionTag, airwayIds)
        .then((result) => {
          for (let i = 0; i < result.length; i++) {
            result[i].color = colorMap.get(+result[i].id);
          }
          setAirways(result);
        })
        .catch(() => {
          toast.error("Failed to load railways");
        });
    } else {
      setAirways([]);
    }
  };

  useEffect(() => {
    updateLinesFromRefs(mapElementReferences);
    updateMapTextsFromRefs(mapElementReferences);
    updateWaypointsFromRefs(mapElementReferences);
    updatePointsFromRefs(mapElementReferences);
    updateAirportsFromRefs(mapElementReferences);
    updateHoldingsFromRefs(mapElementReferences);
    updateLakesFromRefs(mapElementReferences);
    updateAreasFromRefs(mapElementReferences);
    updateUrbanAreasFromRefs(mapElementReferences);
    updateHighwaysFromRefs(mapElementReferences);
    updateHighwayRampsFromRefs(mapElementReferences);
    updateHighwayTunnelsFromRefs(mapElementReferences);
    updateMajorRoadsFromRefs(mapElementReferences);
    updateRailwaysFromRefs(mapElementReferences);
    updateDamParametersFromRefs(mapElementReferences);
    updateAirwaysFromRefs(mapElementReferences);
    updateComputedAreasFromRefs(mapElementReferences);
  }, [mapElementReferences, graphicAttributes]);

  const [isInEdit, setInEdit] = useState(false);
  const isInEditRef = useRef(isInEdit);

  const onStartEdit = () => {
    setInEdit(true);
  };

  const onTextClick = (text: MapText) => {
    if (isInEditRef.current === true && selectedTextRef.current === undefined) {
      setSelectedText(text);
    }
  };

  useEffect(() => {
    isInEditRef.current = isInEdit;
  }, [isInEdit]);

  useEffect(() => {
    selectedTextRef.current = selectedText;
  }, [selectedText]);

  const onTextMoved = (text: MapText, newPosition: L.LatLng) => {
    selectedTextRef.current!.wgs84Latitude = newPosition.lat;
    selectedTextRef.current!.wgs84Longitude = newPosition.lng;
  };

  const onCommit = () => {
    setInEdit(false);
    updateMapText(versionTag, selectedTextRef.current!)
      .then((result) => {
        result.color = selectedTextRef.current?.color; // color graphic attribute is managed at the map level.
        setMapTexts([...mapTexts.filter((t) => t.id !== result.id), result]);
        setSelectedText(undefined);
        toast.success("Map text moved");
      })
      .catch(() => {
        toast.error("failed to update text postion!");
      });
  };

  const onCancel = () => {
    setInEdit(false);
    setSelectedText(undefined);
    updateMapTextsFromRefs(mapElementReferences);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <RadarMapViewer
        lines={lines}
        areas={areas}
        computedAreas={computedAreas}
        texts={mapTexts}
        damParameters={damParameters}
        waypoints={waypoints}
        points={points}
        airports={airports}
        airways={airways}
        holdings={holdings}
        lakes={lakes}
        urbanAreas={urbanAreas}
        highways={highways}
        highwayRamps={highwayRamps}
        highwayTunnels={highwayTunnels}
        majorRoads={majorRoads}
        railways={railways}
        isInEdit={isInEdit}
        selectedText={selectedText}
        onTextClick={onTextClick}
        onTextMoved={onTextMoved}
        onStartEdit={onStartEdit}
        onCancelEdit={onCancel}
        onCommitEdit={onCommit}
      />
    </div>
  );
};

export default MapEditorPreview;
