import { LatLngExpression } from "leaflet";
import React, { useEffect } from "react";
import { useState } from "react";
import { MapContainer } from "react-leaflet";
import {
  Airport,
  Airway,
  Area,
  ComputedArea,
  DamParameter,
  Highway,
  HighwayRamp,
  HighwayTunnel,
  Holding,
  Lake,
  Line,
  MajorRoad,
  MapText,
  Point,
  Railway,
  UrbanArea,
  Waypoint
} from "src/types/Types";
import WaypointMarker from "./layers/WaypointMarker";
import AirportMarker from "./layers/AirportMarker";
import AirwayMarker from "./layers/AirwayMarker";
import HoldingMarker from "./layers/HoldingMarker";
import PointMarker from "./layers/PointMarker";
import { Line as LineLayer } from "./layers/Line";
import { Area as AreaLayer } from "./layers/Area";
import ComputedAreaMarker from "./layers/ComputedAreaMarker";
import TextMarker from "./layers/TextMarker";
import DamParameterMarker from "./layers/DamParameterMarker";
import Lakes from "./layers/Lakes";
import UrbanAreas from "./layers/UrbanAreas";
import Highways from "./layers/Highways";
import HighwayRamps from "./layers/HighwayRamps";
import HighwayTunnels from "./layers/HighwayTunnels";
import Railways from "./layers/Railways";
import MajorRoads from "./layers/MajorRoads";
import Fullscreen from "react-leaflet-fullscreen-plugin";
import EditMapControl from "./layers/EditMapControl";

type RadarMapViewerProps = {
  waypoints?: Waypoint[];
  airports?: Airport[];
  airways?: Airway[];
  points?: Point[];
  holdings?: Holding[];
  texts?: MapText[];
  selectedText?: MapText;
  onTextMoved?: (text: MapText, newPosition: L.LatLng) => void;
  onTextClick?: (text: MapText) => void;
  damParameters?: DamParameter[];
  lines?: Line[];
  areas?: Area[];
  computedAreas?: ComputedArea[];
  lakes?: Lake[];
  urbanAreas?: UrbanArea[];
  highways?: Highway[];
  highwayRamps?: HighwayRamp[];
  highwayTunnels?: HighwayTunnel[];
  majorRoads?: MajorRoad[];
  railways?: Railway[];
  onStartEdit?: () => void;
  onCancelEdit?: () => void;
  onCommitEdit?: () => void;
  isInEdit?: boolean;
};

export interface RadarMapViewerApi {
  invalidateSize: () => void;
}

const RadarMapViewer: React.ForwardRefRenderFunction<RadarMapViewerApi, RadarMapViewerProps> = (props: RadarMapViewerProps, forwardedRef) => {
  const {
    waypoints,
    airports,
    holdings,
    airways,
    points,
    texts,
    damParameters,
    lines,
    areas,
    computedAreas,
    lakes,
    urbanAreas,
    highways,
    highwayRamps,
    highwayTunnels,
    majorRoads,
    railways,
    onTextMoved,
    onTextClick,
    onStartEdit,
    onCommitEdit,
    onCancelEdit,
    isInEdit = false,
    selectedText
  } = props;

  const backgroundColor = "rgb(8,20,16)";
  const mapCenter: LatLngExpression = [46.821488, 7.99639];

  const [map, setMap] = useState<L.Map>();
  const [prioritizedAreas, setPrioritizedAreas] = useState<Area[]>([]);

  React.useImperativeHandle(forwardedRef, () => ({
    invalidateSize() {
      map?.invalidateSize();
    }
  }));

  const _onStartEdit = () => {
    onStartEdit?.();
  };

  const _onCancelEdit = () => {
    onCancelEdit?.();
  };

  const _onCommitEdit = () => {
    onCommitEdit?.();
  };

  const _onTextMoved = (text: MapText, newPosition: L.LatLng) => {
    onTextMoved?.(text, newPosition);
  };

  useEffect(() => {
    console.log("texts updated in radarMapviewer");
  }, [texts]);

  useEffect(() => {
    if (areas) {
      // make sure low priority areas are drawn first
      setPrioritizedAreas(areas.sort((a, b) => (a.priority! > b.priority! ? 1 : a.priority! < b.priority! ? -1 : 0)).reverse());
    }
  }, [areas]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <MapContainer style={{ height: "100%", width: "100%", backgroundColor: backgroundColor }} center={mapCenter} zoom={8} whenCreated={(map) => setMap(map)} preferCanvas>
        {waypoints &&
          waypoints.map((wp) => {
            return <WaypointMarker waypoint={wp} />;
          })}
        {airports &&
          airports.map((a) => {
            return <AirportMarker airport={a} />;
          })}
        {airways &&
          airways.map((a) => {
            return <AirwayMarker airway={a} />;
          })}
        {holdings &&
          holdings.map((h) => {
            return <HoldingMarker holding={h} />;
          })}
        {points &&
          points.map((p) => {
            return <PointMarker point={p} />;
          })}
        {prioritizedAreas && prioritizedAreas.map((a) => <AreaLayer area={a} />)}
        {computedAreas && computedAreas?.map((ca) => <ComputedAreaMarker computedArea={ca} />)}
        {lines && lines.map((l) => <LineLayer line={l} />)}
        {texts &&
          texts.map((t) => {
            return (
              <TextMarker
                key={`${t.id}${t.color ? t.color : ""}`}
                text={t}
                color={t.color}
                draggable={selectedText?.id === t.id && isInEdit}
                onMove={(newPosition: L.LatLng) => {
                  _onTextMoved(t, newPosition);
                }}
                onClick={() => {
                  onTextClick?.(t);
                }}
                selected={selectedText?.id === t.id && isInEdit}
              />
            );
          })}
        {damParameters &&
          damParameters.map((dp) => {
            return <DamParameterMarker key={`${dp.id}${dp.color ? dp.color : ""}`} damParameter={dp} />;
          })}
        {lakes && <Lakes lakes={lakes} />}
        {urbanAreas && <UrbanAreas urbanAreas={urbanAreas} />}
        {highways && <Highways highways={highways} />}
        {highwayRamps && <HighwayRamps highwayRamps={highwayRamps} />}
        {highwayTunnels && <HighwayTunnels highwayTunnels={highwayTunnels} />}
        {majorRoads && <MajorRoads majorRoads={majorRoads} />}
        {railways && <Railways railways={railways} />}
        <Fullscreen
          eventHandlers={{
            enterFullscreen: (event) => console.log("entered fullscreen", event),
            exitFullscreen: (event) => console.log("exited fullscreen", event)
          }}
        />
        <EditMapControl isEditing={isInEdit} onEdit={_onStartEdit} onCommit={_onCommitEdit} onCancel={_onCancelEdit} />
      </MapContainer>
    </div>
  );
};

export default React.forwardRef(RadarMapViewer);
