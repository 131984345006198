import L, { DragEndEvent } from "leaflet";
import { isUndefined } from "lodash";
import { useMemo, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { Marker, Tooltip } from "react-leaflet";

import { MapText } from "src/types/Types";

type TextMarkerProps = {
  text: MapText;
  color?: string;
  tooltipOn?: boolean;
  draggable?: boolean;
  selected?: boolean;
  onMove?: (newPosition: L.LatLng) => void;
  onClick?: () => void;
};

const TextMarker = (props: TextMarkerProps) => {
  const { text, color, onMove, onClick, tooltipOn = false, draggable = false, selected = false } = props;
  const markerRef = useRef<L.Marker>(null);

  const divIcon = L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(
      <div style={{ color: isUndefined(color) ? "#1565C0" : color }}>
        <div style={{ fontFamily: "Inter, sans-serif", fontSize: 10, width: 50, border: selected ? "1px dashed" : "" }}>{text.text}</div>
      </div>
    )
  });

  const eventHandlers = useMemo(
    () => ({
      click() {
        onClick?.();
      },
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          onMove?.(marker.getLatLng());
        }
      }
    }),
    []
  );

  return (
    <div>
      <Marker ref={markerRef} icon={divIcon} position={[text.wgs84Latitude, text.wgs84Longitude]} draggable={draggable} eventHandlers={eventHandlers}>
        {tooltipOn && <Tooltip direction="right">{`[${text.dmsLatitude} ${text.dmsLongitude}]`}</Tooltip>}
      </Marker>
    </div>
  );
};

export default TextMarker;
